<template>
  <div class="about">
    <h1>Über uns</h1>
    <h1>peter milde + partner ...</h1>
    <p>
      steht für innovative, praxisnahe,
      <u>umsetzungsbegleitende</u> Strategieberatung mit über 20-jähriger
      Branchenerfahrung
    </p>
    <ul>
      <li>
        Prozessberatung sowie Change-Management (ganzheitlicher Ansatz)
      </li>
      <li>
        Identifikation maßgeschneiderter Lösungsansätze auf Basis Ihrer
        strategischen Handlungsbedarfe
      </li>
      <li>
        Entwurf kosteneffizienter Lösungen für Ihre Prozesse und Gewährleistung
        der Umsetzung
      </li>
    </ul>

    <h4>Qualifikation unserer Berater</h4>
    <ul>
      <li>
        Die Basis unserer Beratungsleistungen bildet das langjährige Know-how
        unserer Beraterinnen und Berater, die Branchen-, Prozess- und
        Fach-Expertise zu einem integrativen Beratungsansatz verknüpfen
      </li>
      <li>
        Unter Berücksichtigung organisatorischer Aspekte zeigen wir Ihnen die
        Chancen und Perspektiven, die sich durch den Einsatz neuer innovativer
        Steuerungsinstrumente ergeben
      </li>
      <li>
        Bei unserer Beratung von der Einführung bis zur erfolgreichen Umsetzung
        beziehen wir Ihre Unternehmenskultur und Kompetenz mit ein
      </li>
      <li>
        Ziel ist die Erschließung von Mehrwert für Sie - unseren Kunden
      </li>
    </ul>
  </div>
</template>
